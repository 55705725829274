@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;400;900&display=swap);
body {
  background-color: #212121;
  font-family: 'Open sans', sans-serif;
  color: white;
}
.ant-checkbox-group {
  width: 100%;
}
.h25 {
  height: 25px;
}
.pointer {
  cursor: pointer;
}
.inline-block {
  vertical-align: middle;
  display: inline-block;
}
.bold {
  font-weight: bold;
}
.light {
  font-weight: 100;
}
.min-height-300 {
  min-height: 300px;
}
.block {
  display: block;
}
.left {
  float: left;
}
.underline {
  text-decoration: underline;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.bottom10 {
  bottom: 10px;
}
.bottom0 {
  bottom: 0px;
}
.lh1 {
  line-height: 1;
}
.lh1-5 {
  line-height: 1.5;
}
.flex-wrap {
  flex-wrap: wrap;
}
.btn-2-cols div:nth-child(1) {
  text-align: right;
}
.btn-2-cols div:nth-child(2) {
  text-align: left;
}
.hidden {
  display: none !important;
}
.fw-400,
.fw400 {
  font-weight: 400 !important;
}
.fw-500,
.fw500 {
  font-weight: 500 !important;
}
.fw-600,
.fw600 {
  font-weight: 600;
}
.fw-700,
.fw700 {
  font-weight: 700;
}
.white {
  color: #fff !important;
}
.red {
  color: #d0021b;
}
.green {
  color: #009f4f;
}
.blue {
  color: #0f339b;
}
.black {
  color: #333 !important;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.bg-white,
.bgwhite {
  background: #fff;
}
.bg-white-opacity {
  background: rgba(255,255,255,0.9);
}
.w32 {
  width: 32px;
}
.w77 {
  width: 77px !important;
}
.w85 {
  width: 85px !important;
}
.w90 {
  width: 90px !important;
}
.w100 {
  width: 100px;
}
.w118 {
  width: 118px;
}
.w128 {
  width: 128px !important;
}
.w150 {
  width: 150px;
}
.w160 {
  width: 160px;
}
.w220 {
  width: 220px;
}
.w250 {
  width: 250px;
}
.w260 {
  width: 260px;
}
.w350 {
  width: 350px;
}
.w370 {
  width: 370px;
}
.w800 {
  width: 800px !important;
}
.left-divider {
  box-shadow: -1px 0 0 0 rgba(0,0,0,0.25);
}
.right-divider {
  box-shadow: 1px 0 0 0 rgba(0,0,0,0.25);
}
.bottom-shadow {
  box-shadow: 10px 0 0 0 #000;
}
.fill {
  width: 100% !important;
  max-width: 100% !important;
}
.fill-half {
  width: 50%;
}
.fill-h {
  height: 100%;
}
.maxh100 {
  max-height: 100%;
}
.fs11 {
  font-size: 11px;
}
.fs12 {
  font-size: 12px;
}
.fs13 {
  font-size: 13px;
}
.fs16 {
  font-size: 16px !important;
}
.fs18 {
  font-size: 18px;
}
.fs20 {
  font-size: 20px;
}
.bblackdashed {
  border: 0 dashed rgba(0,0,0,0.8);
}
.bblackdotted {
  border: 0 dotted rgba(0,0,0,0.8);
}
.bblack {
  border: 0 solid rgba(0,0,0,0.8);
}
.bbblack {
  border-bottom: 1px solid rgba(0,0,0,0.8) !important;
}
.bl {
  border-left-width: 1px;
}
.br {
  border-right-width: 1px;
}
.bt {
  border-top-width: 1px;
}
.bb {
  border-bottom-width: 1px;
}
.bgray {
  border: 1px solid #ddd;
}
.brgray {
  border-right: 1px solid rgba(0,0,0,0.15);
}
.blgray {
  border-left: 1px solid rgba(0,0,0,0.1) !important;
}
.bbgray {
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.bb0 {
  border-bottom: 0 !important;
}
.btgray {
  border-top: 1px solid rgba(0,0,0,0.1);
}
.bggray {
  background: rgba(0,0,0,0.1);
}
.gray {
  color: rgba(0,0,0,0.4);
}
.overflowhidden {
  overflow: hidden;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.align-stretch {
  align-items: stretch;
}
.flex-1 {
  flex: 1 1 !important;
}
.flex-1-5 {
  flex: 1.5 1 !important;
}
.flex-2 {
  flex: 2 1 !important;
}
.flex-3 {
  flex: 3 1 !important;
}
.radius10 {
  border-radius: 10px;
}
.flex-v {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.flex-v .flex-1 {
  flex: 1 1;
}
.flex-v.flex-end {
  align-items: flex-end;
  justify-content: flex-end;
}
.flex-v.flex-start {
  align-items: flex-start !important;
}
.flex-v.flex-center {
  justify-content: center;
}
.flex-v.flex-align-center {
  align-items: center;
}
.flex-v .flex-align-self-center {
  align-self: center;
}
.flex-h {
  display: flex !important;
  flex-direction: row;
}
.flex-h .flex-1 {
  flex: 1 1;
}
.flex-h.flex-end {
  align-items: flex-end;
  justify-content: flex-end;
}
.flex-h.flex-start {
  align-items: flex-start !important;
}
.flex-h.flex-center {
  justify-content: center;
  align-items: center;
}
.flex-h.flex-text-left {
  justify-content: left;
}
.flex-h .flex-align-self-center {
  align-self: center;
}
.minw70 {
  min-width: 70px;
}
.maxw88 {
  max-width: 88px;
}
.maxw160 {
  max-width: 160px;
}
.maxw120 {
  max-width: 120px;
}
.maxw320 {
  max-width: 320px;
}
.maxw500 {
  max-width: 500px;
}
.maxw650 {
  max-width: 650px;
}
.maxw400 {
  max-width: 400px;
}
.maxw480 {
  max-width: 480px;
}
.maxw925 {
  max-width: 925px;
}
.maxw1030 {
  max-width: 1030px;
}
.maxw1100 {
  max-width: 1100px !important;
}
.maxw750 {
  max-width: 750px;
}
.maxw710 {
  max-width: 710px;
}
.maxw693 {
  max-width: 693px;
}
.h18 {
  height: 18px;
}
.h52 {
  height: 52px;
}
.lh16 {
  line-height: 16px;
}
.lh30 {
  line-height: 30px;
}
.h46 {
  height: 46px;
}
.h120 {
  height: 120px;
}
.h500 {
  height: 500px;
}
.m0 {
  margin: 0 !important;
}
.m1 {
  margin: 1px;
}
.m2 {
  margin: 2px;
}
.m3 {
  margin: 3px;
}
.m4 {
  margin: 4px;
}
.m5 {
  margin: 5px;
}
.m6 {
  margin: 6px;
}
.m7 {
  margin: 7px;
}
.m8 {
  margin: 8px;
}
.m9 {
  margin: 9px;
}
.m10 {
  margin: 10px;
}
.m15 {
  margin: 15px;
}
.m20 {
  margin: 20px;
}
.mt0 {
  margin-top: 0px !important;
}
.mt1 {
  margin-top: 1px;
}
.mt2 {
  margin-top: 2px;
}
.mt3 {
  margin-top: 3px;
}
.mt4 {
  margin-top: 4px;
}
.mt5 {
  margin-top: 5px !important;
}
.mt6 {
  margin-top: 6px;
}
.mt7 {
  margin-top: 7px;
}
.mt8 {
  margin-top: 8px;
}
.mt10 {
  margin-top: 10px !important;
}
.mt12 {
  margin-top: 12px;
}
.mt13 {
  margin-top: 13px;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mt25 {
  margin-top: 25px;
}
.mt30 {
  margin-top: 30px !important;
}
.mt35 {
  margin-top: 35px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mt50 {
  margin-top: 50px !important;
}
.mt52 {
  margin-top: 52px;
}
.mt60 {
  margin-top: 60px;
}
.mt100 {
  margin-top: 100px;
}
.mt105 {
  margin-top: 105px;
}
.mr0 {
  margin-right: 0;
}
.mr1 {
  margin-right: 1px;
}
.mr2 {
  margin-right: 2px;
}
.mr3 {
  margin-right: 3px;
}
.mr4 {
  margin-right: 4px;
}
.mr5 {
  margin-right: 5px !important;
}
.mr6 {
  margin-right: 6px;
}
.mr7 {
  margin-right: 7px;
}
.mr8 {
  margin-right: 8px;
}
.mr10 {
  margin-right: 10px !important;
}
.mr12 {
  margin-right: 12px !important;
}
.mr15 {
  margin-right: 15px !important;
}
.mr20 {
  margin-right: 20px;
}
.mr30 {
  margin-right: 30px;
}
.mr25 {
  margin-right: 25px;
}
.mr55 {
  margin-right: 55px;
}
.mb-15 {
  margin-bottom: -15px;
}
.mb0 {
  margin-bottom: 0px !important;
}
.mb1 {
  margin-bottom: 1px;
}
.mb2 {
  margin-bottom: 2px;
}
.mb3 {
  margin-bottom: 3px;
}
.mb4 {
  margin-bottom: 4px;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb16 {
  margin-bottom: 16px !important;
}
.mb18 {
  margin-bottom: 18px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb24 {
  margin-bottom: 24px !important;
}
.mb25 {
  margin-bottom: 25px !important;
}
.mb30 {
  margin-bottom: 30px;
}
.mb35 {
  margin-bottom: 35px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb45 {
  margin-bottom: 45px;
}
.mb50 {
  margin-bottom: 50px !important;
}
.mb100 {
  margin-bottom: 100px;
}
.ml1 {
  margin-left: 1px !important;
}
.ml2 {
  margin-left: 2px;
}
.ml3 {
  margin-left: 3px;
}
.ml4 {
  margin-left: 4px;
}
.ml5 {
  margin-left: 5px;
}
.ml6 {
  margin-left: 6px;
}
.ml7 {
  margin-left: 7px;
}
.ml8 {
  margin-left: 8px;
}
.ml10 {
  margin-left: 10px !important;
}
.ml12 {
  margin-left: 12px;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}
.ml24 {
  margin-left: 24px;
}
.ml25 {
  margin-left: 25px;
}
.ml30 {
  margin-left: 30px;
}
.ml40 {
  margin-left: 40px;
}
.ml50 {
  margin-left: 50px;
}
.ml60 {
  margin-left: 60px;
}
.ml70 {
  margin-left: 70px;
}
.ml95 {
  margin-left: 95px;
}
.mv1 {
  margin-top: 1px;
  margin-bottom: 1px;
}
.mv2 {
  margin-top: 2px;
  margin-bottom: 2px;
}
.mv3 {
  margin-top: 3px;
  margin-bottom: 3px;
}
.mv4 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.mv5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.mv10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mv15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.mv20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.mv30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.mv50 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.mh0 {
  margin-left: 0px;
  margin-right: 0px;
}
.mh1 {
  margin-left: 1px;
  margin-right: 1px;
}
.mh2 {
  margin-left: 2px;
  margin-right: 2px;
}
.mh3 {
  margin-left: 3px;
  margin-right: 3px;
}
.mh4 {
  margin-left: 4px;
  margin-right: 4px;
}
.mh5 {
  margin-left: 5px;
  margin-right: 5px;
}
.mh7 {
  margin-left: 7px;
  margin-right: 7px;
}
.mh10 {
  margin-left: 10px;
  margin-right: 10px;
}
.mh15 {
  margin-left: 15px;
  margin-right: 15px;
}
.mh20 {
  margin-left: 20px;
  margin-right: 20px;
}
.mh30 {
  margin-left: 30px;
  margin-right: 30px;
}
.mauto {
  margin: 0 auto;
}
.mhauto {
  margin-left: auto;
  margin-right: auto;
}
.p0 {
  padding: 0 !important;
}
.p1 {
  padding: 1px;
}
.p2 {
  padding: 2px;
}
.p3 {
  padding: 3px;
}
.p4 {
  padding: 4px;
}
.p5 {
  padding: 5px !important;
}
.p6 {
  padding: 6px;
}
.p7 {
  padding: 7px;
}
.p8 {
  padding: 8px;
}
.p9 {
  padding: 9px;
}
.p10 {
  padding: 10px;
}
.p12 {
  padding: 12px;
}
.p15 {
  padding: 15px;
}
.p20 {
  padding: 20px;
}
.p24 {
  padding: 24px;
}
.p25 {
  padding: 25px;
}
.p30 {
  padding: 30px;
}
.p40 {
  padding: 40px;
}
.p50 {
  padding: 50px;
}
.pt0 {
  padding-top: 0px;
}
.pt1 {
  padding-top: 1px;
}
.pt2 {
  padding-top: 2px;
}
.pt3 {
  padding-top: 3px;
}
.pt4 {
  padding-top: 4px;
}
.pt5 {
  padding-top: 5px;
}
.pt6 {
  padding-top: 6px;
}
.pt7 {
  padding-top: 7px;
}
.pt8 {
  padding-top: 8px;
}
.pt9 {
  padding-top: 9px;
}
.pt10 {
  padding-top: 10px;
}
.pt12 {
  padding-top: 12px;
}
.pt15 {
  padding-top: 15px !important;
}
.pt20 {
  padding-top: 20px !important;
}
.pt24 {
  padding-top: 24px !important;
}
.pt25 {
  padding-top: 25px;
}
.pt30 {
  padding-top: 30px;
}
.pt33 {
  padding-top: 33px;
}
.pt40 {
  padding-top: 40px;
}
.pt50 {
  padding-top: 50px;
}
.pt54 {
  padding-top: 54px;
}
.pt60 {
  padding-top: 60px;
}
.pt64 {
  padding-top: 64px;
}
.pt100 {
  padding-top: 100px;
}
.pb0 {
  padding-bottom: 0px !important;
}
.pb1 {
  padding-bottom: 1px;
}
.pb2 {
  padding-bottom: 2px;
}
.pb3 {
  padding-bottom: 3px;
}
.pb4 {
  padding-bottom: 4px;
}
.pb5 {
  padding-bottom: 5px !important;
}
.pb6 {
  padding-bottom: 6px;
}
.pb10 {
  padding-bottom: 10px !important;
}
.pb15 {
  padding-bottom: 15px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb24 {
  padding-bottom: 24px;
}
.pb25 {
  padding-bottom: 25px;
}
.pb30 {
  padding-bottom: 30px;
}
.pb35 {
  padding-bottom: 35px;
}
.pb40 {
  padding-bottom: 40px;
}
.pb50 {
  padding-bottom: 50px;
}
.pl1 {
  padding-left: 1px;
}
.pl2 {
  padding-left: 2px;
}
.pl3 {
  padding-left: 3px;
}
.pl4 {
  padding-left: 4px;
}
.pl5 {
  padding-left: 5px !important;
}
.pl8 {
  padding-left: 8px;
}
.pl10 {
  padding-left: 10px;
}
.pl15 {
  padding-left: 15px;
}
.pl20 {
  padding-left: 20px;
}
.pl24 {
  padding-left: 24px;
}
.pl30 {
  padding-left: 30px;
}
.pl40 {
  padding-left: 40px;
}
.pl50 {
  padding-left: 50px;
}
.pr1 {
  padding-right: 1px;
}
.pr2 {
  padding-right: 2px;
}
.pr3 {
  padding-right: 3px;
}
.pr4 {
  padding-right: 4px;
}
.pr5 {
  padding-right: 5px;
}
.pr7 {
  padding-right: 7px;
}
.pr10 {
  padding-right: 10px;
}
.pr15 {
  padding-right: 15px;
}
.pr20 {
  padding-right: 20px;
}
.pr24 {
  padding-right: 24px;
}
.pr25 {
  padding-right: 25px;
}
.pr30 {
  padding-right: 30px;
}
.pr40 {
  padding-right: 40px;
}
.pr50 {
  padding-right: 50px;
}
.pv0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.pv1 {
  padding-top: 1px;
  padding-bottom: 1px;
}
.pv2 {
  padding-top: 2px;
  padding-bottom: 2px;
}
.pv3 {
  padding-top: 3px;
  padding-bottom: 3px;
}
.pv4 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.pv5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.pv6 {
  padding-top: 6px;
  padding-bottom: 6px;
}
.pv7 {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.pv8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.pv10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.pv14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
.pv15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.pv20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.pv30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.pv50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.pv80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
.ph0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.ph1 {
  padding-left: 1px;
  padding-right: 1px;
}
.ph2 {
  padding-left: 2px;
  padding-right: 2px;
}
.ph3 {
  padding-left: 3px;
  padding-right: 3px;
}
.ph4 {
  padding-left: 4px;
  padding-right: 4px;
}
.ph5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.ph10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.ph15 {
  padding-left: 15px;
  padding-right: 15px;
}
.ph20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.ph24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.ph25 {
  padding-left: 25px;
  padding-right: 25px;
}
.ph30 {
  padding-left: 30px;
  padding-right: 30px;
}
.ph50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.b30 {
  bottom: 30px;
}
.l20 {
  left: 20px;
}
.middle {
  vertical-align: middle;
}
.ant-message {
  top: 50px !important;
}
.left-auto {
  left: auto;
}
.lightgreen-border {
  border: 2px solid #90ee90;
}
.salmon-border {
  border: 2px solid #fa8072;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



